<template>
  <b-card>
    <l-map :zoom="zoom" :center="center" :options="mapOptions">
      <l-tile-layer :url="tile.url" :attribution="tile.attr" class="map" />
      <l-marker
        v-for="sensor in sensors"
        :key="sensor.id"
        :lat-lng="sensor.location.split(',')"
        @click="onSelect(sensor.id)"
      >
      </l-marker>
    </l-map>
  </b-card>
</template>

<script>
import { Icon } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import useAppConfig from "@core/app-config/useAppConfig";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "LeaftletBasic",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: ["sensors"],
  data() {
    return {
      tile: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attr: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      },

      zoom: 11,
      center: [39.9334, 32.8597],
      mapOptions: {
        dragging: !L.Browser.mobile,
      },
    };
  },
  methods: {
    onSelect(id) {
      this.$emit("sensor-selected", id);
    },
  },
  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 535px;
  }
}
.leaflet-tile {
  transition: filter 100ms ease-in-out;
}
.dark-layout .leaflet-tile {
  filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3)
    brightness(0.7);
  transition: filter 100ms ease-in-out;
}

.leaflet-top.leaflet-left {
  z-index: 400 !important;
}
</style>
