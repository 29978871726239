<template>
  <b-card
    :img-src="
      sensor.cover_images.length > 0 ? sensor.cover_images[0].url : null
    "
    v-if="sensor"
    img-bottom
    img-alt="card img"
    :title="sensor.name"
    class="sensor-info-card"
  >
    <b-card-text> {{ sensor.description }} </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["sensorId"],
  computed: {
    ...mapGetters(["sensorById"]),
    sensor() {
      return this.sensorById(this.sensorId);
    },
  },
};
</script>

<style>
.sensor-info-card img {
  flex-grow: 5;
  object-fit: cover;
  object-position: center;
}
</style>