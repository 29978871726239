<template>
  <statistic-card-with-area-chart
    :statistic="stat | abbrNumber"
    :statistic-title="title"
    :chartData="series"
    :loading="loading"
    :color="color"
    :info="date"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </statistic-card-with-area-chart>
</template>

<script>
import axios from "@axios";
import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { intlFormat, parseISO } from "date-fns";

export default {
  props: ["title", "group", "icon", "sensorId", "type", "color"],
  components: {
    StatisticCardWithAreaChart,
  },
  watch: {
    sensorId() {
      this.refreshData();
    },
  },
  data() {
    return {
      stat: 0,
      series: [],
      loading: true,
      date: null,
    };
  },
  methods: {
    refreshData() {
      this.loading = true;
      axios
        .get("/api/v1/detections/stats", {
          params: {
            sensor_id: this.sensorId,
            limit: 7,
            group: this.group,
            detection_type: this.type,
          },
        })
        .then(({ data }) => {
          let entries = this.$options.filters.period(data.reverse());

          if (entries.length > 0) {
            this.date = intlFormat(
              parseISO(entries[entries.length - 1].date),
              {
                day: "numeric",
                month: "long",

                year: "numeric",
              },
              { locale: "tr-TR" }
            );
            entries = entries.map((d) => {
              return d.count || 0;
            });
            this.stat = entries[entries.length - 1] || 0;
          } else {
            this.stat = 0;
          }
          this.series = [{ name: "", data: entries }];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style>
</style>
