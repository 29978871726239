<template>
  <b-card
    text-variant="center"
    v-if="organization"
    :class="
      'card-congratulations organization-card ' +
      (organization ? '' : 'card-hidden')
    "
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <div v-if="organization">
      <div class="d-flex justify-content-center gap-2">
        <b-avatar
          v-for="icon in organization.icons"
          :key="icon.id"
          variant="primary"
          size="70"
          class="shadow m-2"
          :src="icon.url"
          :title="icon.name"
        />
      </div>
      <h1 class="mb-1 mt-50 text-white">{{ organization.name }}</h1>
      <b-card-text class="m-auto w-75">
        {{ organization.description }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Organization",
  props: ["sensorId"],
  computed: {
    ...mapGetters(["sensors", "sensorById"]),
    organization() {
      if (!this.sensorId || !this.sensorById(this.sensorId)) {
        return null;
      }
      return this.sensorById(this.sensorId).organization;
    },
  },
};
</script>
<style lang="scss">
.organization-card {
  position: relative;
  transform-origin: top;
  height: 250px;
  overflow: hidden !important;
  transition: height 0.3s linear, margin 0.1s linear 0.3s;

  &.card-hidden {
    height: 0px;
    margin-bottom: 0;
    transition: margin 0.1s linear, height 0.3s linear 0.1s;
  }
}
.b-avatar-img img {
  object-fit: scale-down !important;
}

@media only screen and (max-width: 600px) {
  .organization-card {
    height: 280px;
  }
}
</style>