<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100"
    style="height: 50vh"
    v-if="loading"
  >
    <span class="block p-2">Yükleniyor</span>
    <b-spinner class="block align-center" />
  </div>
  <b-container v-else :class="containerClass">
    <organization class="organization" :sensor-id="sensorId" />
    <sensor-name class="sensor-name" v-if="sensorId" :sensor-id="sensorId" />
    <sensor-info class="sensor-info" v-if="sensorId" :sensor-id="sensorId" />
    <stat
      icon="walk"
      group="day"
      title="Bisiklet"
      :sensor-id="sensorId"
      type="2"
      color="warning"
      class="stat-bike"
    >
      <template v-slot:icon-slot>
        <bike />
      </template>
    </stat>
    <stat
      icon="bike"
      group="day"
      title="Kişi"
      :sensor-id="sensorId"
      type="1"
      color="primary"
      class="stat-walk"
    >
      <template v-slot:icon-slot>
        <walk />
      </template>
    </stat>
    <leaflet-basic
      class="map"
      :sensors="sensors"
      @sensor-selected="sensorSelected"
    />
    <bar-chart class="bar-chart" :sensor-id="sensorId" />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Organization from "./components/Organization.vue";
import SensorInfo from "./components/SensorInfo.vue";
import SensorName from "./components/SensorName.vue";
import Stat from "./components/Stat.vue";
import Walk from "vue-material-design-icons/Walk.vue";
import Bike from "vue-material-design-icons/Bike.vue";

import LeafletBasic from "./components/LeafletBasic.vue";
import BarChart from "./components/BarChart.vue";

export default {
  name: "SensorDashboard",
  components: {
    Organization,
    SensorInfo,
    SensorName,
    Stat,
    Walk,
    Bike,
    LeafletBasic,
    BarChart,
  },
  props: ["sensorId"],
  computed: {
    ...mapGetters(["sensors", "sensorById"]),
    containerClass() {
      let classes = ["layout"];

      if (this.sensorId) {
        classes.push("has-sensor-info");

        if (
          this.sensorById(this.sensorId) &&
          this.sensorById(this.sensorId).organization
        ) {
          classes.push("has-organization");
        }
      }

      return classes.join(" ");
    },
  },
  data() {
    return { loading: true };
  },
  methods: {
    ...mapActions(["fetchSensors"]),
    sensorSelected(id) {
      this.$emit("sensor-selected", id);
    },
  },
  async mounted() {
    await this.fetchSensors();
    if (!this.sensorId) {
      this.$emit("sensor-selected", this.sensors[0].id);
    }
    this.loading = false;
  },
};
</script>



<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: min-content min-content min-content min-content;
}

.layout div {
  margin: 0.5rem 0;
}

.layout {
  .organization {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .sensor-name {
    grid-column: 1/5;
    grid-row: 2/3;
  }

  .stat-bike {
    grid-column: 1/5;
    grid-row: 4/5;
  }
  .stat-walk {
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .map {
    grid-row: 6/7;
    grid-column: 1/5;
  }

  .sensor-info {
    grid-row: 7/8;
    grid-column: 1/5;
  }

  .bar-chart {
    grid-row: 8/9;
    grid-column: 1/5;
  }
}

@media only screen and (min-width: 1020px) {
  .layout div {
    margin: 1rem;
  }
  .sensor-name {
    display: none;
  }
  .layout {
    .map {
      grid-column: 1/3;
      grid-row: 1/3;
    }

    .stat-bike {
      grid-column: 3/4;
      grid-row: 1/2;
    }

    .stat-walk {
      grid-column: 4/5;
      grid-row: 1/2;
    }

    .bar-chart {
      grid-column: 3/5;
      grid-row: 2/4;
    }
  }

  .layout.has-organization {
    .organization {
      grid-column: 1/3;
      grid-row: 1/2;
    }
    .map {
      grid-column: 1/3;
      grid-row: 2/4;
    }
  }

  .layout.has-sensor-info {
    .sensor-info {
      grid-column-start: 3;
      grid-column-end: 3;
      grid-row: 1/3;
    }

    .stat-bike {
      grid-column: 4/5;
      grid-row: 1/2;
    }

    .stat-walk {
      grid-column: 4/5;
      grid-row: 2/3;
    }

    .bar-chart {
      grid-column: 3/5;
      grid-row: 3/5;
    }
  }
}
</style>


<style>
.b-avatar-custom .material-design-icon {
  display: block;
  width: 2rem;
  height: 2rem;
}
.b-avatar-custom .material-design-icon__svg {
  width: 2rem !important;
  height: 2rem !important;
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.sensor-info-card {
  height: 495px;
}

.sensor-info-card img {
  flex-grow: 5;
  object-fit: cover;
  object-position: center;
}
</style>