<template>
  <b-card>
    <h3 class="mb-0" v-if="sensor">{{ sensor.name }}</h3>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["sensorId"],
  computed: {
    ...mapGetters(["sensorById"]),
    sensor() {
      return this.sensorById(this.sensorId);
    },
  },
};
</script>

<style>
</style>