<template>
  <b-card>
    <b-row style="padding-left: 0; padding-right: 0">
      <b-col sm="12" md="6">
        <b-form-group label="Tanım">
          <v-select v-model="type" :options="types" placeholder="Hepsi">
            <template #option="{ label, icon }">
              <component :is="icon" />
              <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
              <span> {{ label }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group label="Zaman Aralığı" style="text-align: end">
          <v-select
            v-model="interval"
            :options="intervals"
            placeholder="Hepsi"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-spinner
        v-if="loading"
        variant="primary"
        small
        class="loading-indicator"
      />
      <vue-apex-charts
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </b-card>
</template>

<script>
import axios from "@axios";
import VueApexCharts from "vue-apexcharts";
import VSelect from "vue-select";
import Walk from "vue-material-design-icons/Walk.vue";
import Bike from "vue-material-design-icons/Bike.vue";
import { intlFormat, parseISO, startOfMonth } from "date-fns";
import differenceInDays from "date-fns/differenceInDays/index.js";
import { differenceInMonths } from "date-fns/esm";
import startOfYear from "date-fns/startOfYear";

export default {
  props: ["title", "group", "icon", "sensorId"],
  components: { VueApexCharts, VSelect, Walk, Bike },
  watch: {
    sensorId() {
      this.refreshData();
    },
    interval() {
      this.refreshData();
    },
    type() {
      this.refreshData();
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        colors: ["#28c76f", "#00cfe8", "#d2b0ff"],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            endingShape: "rounded",
            radius: 15,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categories,
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
  data() {
    return {
      stat: 0,
      loading: true,
      series: [],
      categories: [],
      type: null,
      types: [
        { label: "İnsan", value: "1", icon: "walk" },
        { label: "Bisiklet", value: "2", icon: "bike" },
      ],
      interval: { label: "Gün", value: "day", timespan: 15 },
      intervals: [
        { label: "Gün", value: "day", timespan: 15 },
        { label: "Ay", value: "month", timespan: 12 },
        { label: "Yıl", value: "year", timespan: 10 },
      ],
    };
  },
  methods: {
    refreshData() {
      this.loading = true;
      axios
        .get("/api/v1/detections/stats", {
          params: {
            sensor_id: this.sensorId,
            limit: this.calculateSamplingSpan(
              this.interval["value"],
              this.interval["timespan"]
            ),
            group: this.interval["value"],
            detection_type: this.type ? this.type["value"] : null,
          },
        })
        .then(({ data }) => {
          data.reverse();
          data =
            this.interval.value == "day"
              ? this.$options.filters.period(data)
              : data;
          this.series = [{ name: "Geçiş", data: data.map((d) => d.count) }];
          this.categories = data.map((d) =>
            intlFormat(
              parseISO(d.date),
              {
                year: { year: "numeric" },
                month: { month: "long", year: "numeric" },
                day: { day: "numeric", month: "long" },
              }[this.interval.value]
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calculateSamplingSpan(type, span) {
      if (type == "day") {
        return Math.min(
          differenceInDays(new Date(), startOfMonth(new Date())) + 1,
          span
        );
      }
      if (type == "month") {
        return Math.min(
          differenceInMonths(new Date(), startOfYear(new Date())) + 1,
          span
        );
      }
      return span;
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style scoped>
.loading-indicator {
  position: absolute;
  top: 0;
  right: 1rem;
}
</style>
